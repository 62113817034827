import React from 'react'

import {Outlet} from "react-router-dom";

function Layout1() {
  return (
    <>
    
    <Outlet />
    
 </>
  )
}

export default Layout1